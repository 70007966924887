// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-maintenance-jsx": () => import("./../../../src/pages/maintenance.jsx" /* webpackChunkName: "component---src-pages-maintenance-jsx" */),
  "component---src-pages-page-not-found-jsx": () => import("./../../../src/pages/page-not-found.jsx" /* webpackChunkName: "component---src-pages-page-not-found-jsx" */),
  "component---src-pages-purchase-account-jsx": () => import("./../../../src/pages/purchase/account.jsx" /* webpackChunkName: "component---src-pages-purchase-account-jsx" */),
  "component---src-pages-purchase-account-summary-jsx": () => import("./../../../src/pages/purchase/account-summary.jsx" /* webpackChunkName: "component---src-pages-purchase-account-summary-jsx" */),
  "component---src-pages-purchase-activation-success-jsx": () => import("./../../../src/pages/purchase/activation-success.jsx" /* webpackChunkName: "component---src-pages-purchase-activation-success-jsx" */),
  "component---src-pages-purchase-activation-unsuccessful-jsx": () => import("./../../../src/pages/purchase/activation-unsuccessful.jsx" /* webpackChunkName: "component---src-pages-purchase-activation-unsuccessful-jsx" */),
  "component---src-pages-purchase-contract-preparation-jsx": () => import("./../../../src/pages/purchase/contract-preparation.jsx" /* webpackChunkName: "component---src-pages-purchase-contract-preparation-jsx" */),
  "component---src-pages-purchase-contract-preparation-success-jsx": () => import("./../../../src/pages/purchase/contract-preparation-success.jsx" /* webpackChunkName: "component---src-pages-purchase-contract-preparation-success-jsx" */),
  "component---src-pages-purchase-customer-collection-jsx": () => import("./../../../src/pages/purchase/customer-collection.jsx" /* webpackChunkName: "component---src-pages-purchase-customer-collection-jsx" */),
  "component---src-pages-purchase-customer-collection-success-jsx": () => import("./../../../src/pages/purchase/customer-collection-success.jsx" /* webpackChunkName: "component---src-pages-purchase-customer-collection-success-jsx" */),
  "component---src-pages-purchase-device-info-unsuccessful-jsx": () => import("./../../../src/pages/purchase/device-info-unsuccessful.jsx" /* webpackChunkName: "component---src-pages-purchase-device-info-unsuccessful-jsx" */),
  "component---src-pages-purchase-frequently-asked-questions-jsx": () => import("./../../../src/pages/purchase/frequently-asked-questions.jsx" /* webpackChunkName: "component---src-pages-purchase-frequently-asked-questions-jsx" */),
  "component---src-pages-purchase-imei-jsx": () => import("./../../../src/pages/purchase/imei.jsx" /* webpackChunkName: "component---src-pages-purchase-imei-jsx" */),
  "component---src-pages-purchase-index-jsx": () => import("./../../../src/pages/purchase/index.jsx" /* webpackChunkName: "component---src-pages-purchase-index-jsx" */),
  "component---src-pages-purchase-plans-jsx": () => import("./../../../src/pages/purchase/plans.jsx" /* webpackChunkName: "component---src-pages-purchase-plans-jsx" */),
  "component---src-pages-purchase-qr-scan-jsx": () => import("./../../../src/pages/purchase/qr-scan.jsx" /* webpackChunkName: "component---src-pages-purchase-qr-scan-jsx" */),
  "component---src-pages-purchase-return-jsx": () => import("./../../../src/pages/purchase/return.jsx" /* webpackChunkName: "component---src-pages-purchase-return-jsx" */),
  "component---src-pages-purchase-reupload-device-photo-jsx": () => import("./../../../src/pages/purchase/reupload-device-photo.jsx" /* webpackChunkName: "component---src-pages-purchase-reupload-device-photo-jsx" */),
  "component---src-pages-purchase-reupload-device-photo-success-jsx": () => import("./../../../src/pages/purchase/reupload-device-photo-success.jsx" /* webpackChunkName: "component---src-pages-purchase-reupload-device-photo-success-jsx" */),
  "component---src-pages-purchase-review-order-jsx": () => import("./../../../src/pages/purchase/review-order.jsx" /* webpackChunkName: "component---src-pages-purchase-review-order-jsx" */),
  "component---src-pages-purchase-system-error-jsx": () => import("./../../../src/pages/purchase/system-error.jsx" /* webpackChunkName: "component---src-pages-purchase-system-error-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-01-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/01.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-01-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-02-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/02.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-02-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-03-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/03.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-03-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-04-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/04.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-04-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-05-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/05.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-05-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-06-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/06.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-06-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-07-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/07.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-07-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-08-lite-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/08-lite.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-08-lite-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-08-premium-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/08-premium.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-08-premium-jsx" */),
  "component---src-pages-purchase-terms-and-conditions-report-jsx": () => import("./../../../src/pages/purchase/terms-and-conditions/report.jsx" /* webpackChunkName: "component---src-pages-purchase-terms-and-conditions-report-jsx" */),
  "component---src-pages-purchase-upload-success-jsx": () => import("./../../../src/pages/purchase/upload-success.jsx" /* webpackChunkName: "component---src-pages-purchase-upload-success-jsx" */),
  "component---src-pages-purchase-verify-device-photo-jsx": () => import("./../../../src/pages/purchase/verify-device-photo.jsx" /* webpackChunkName: "component---src-pages-purchase-verify-device-photo-jsx" */),
  "component---src-pages-purchase-verify-wearables-photo-jsx": () => import("./../../../src/pages/purchase/verify-wearables-photo.jsx" /* webpackChunkName: "component---src-pages-purchase-verify-wearables-photo-jsx" */)
}

